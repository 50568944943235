@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* https://leerob.io/blog/how-stripe-designs-beautiful-websites */
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="range"]::-webkit-slider-thumb {
    @apply appearance-none rounded-full w-[18px] h-[18px] bg-legacy-primary-900 cursor-pointer;
  }

  .scrollbar-hide {
    -ms-overflow-style: none;

    /* firefox */
    scrollbar-width: none;

    /* safari and chrome */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  /* Prevent auto-outlining */
  * {
    @apply outline-none;
  }
  *:focus {
    @apply outline-none;
  }

  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .canny {
    background-color: green;
  }

  /* Canny Changelog Widget */
  .Canny_BadgeContainer {
    @apply overflow-visible visible;
  }
  .Canny_BadgeContainer .Canny_Badge {
    @apply pointer-events-none absolute shrink-0 w-3 h-3 top-0 right-0 m-0.5 rounded-full outline-none focus:outline-none ring-2 ring-legacy-neutralold-25 dark:ring-legacy-neutral-900 animate-bounce transition bg-legacy-red-base group-hover:ring-legacy-neutral-200;
    border: none !important;
  }
}

@media (max-width: 768px) {
  .intercom-lightweight-app-launcher {
    bottom: 72px !important;
  }

  .intercom-dfosxs {
    bottom: 72px !important;
  }

  iframe[name="intercom-notifications-frame"] {
    bottom: 124px !important;
  }
}
